import React from 'react'
import Link from 'next/link'
import HTMLHead from '../components/HTMLHead'
import { useRouter } from 'next/router'
import RetainQueryLink from '../components/RetainQueryLink'

const NotFoundPage = () => {
  const { asPath } = useRouter()
  const addNoIndex = asPath.includes('/manage')
  return (
    <div
      className={
        'w-full flex min-h-[80vh] items-center content-center mt-7 flex-col h-full justify-around'
      }
    >
      <HTMLHead
        title="Page not found."
        description="This page doesn’t exist."
        noIndex={addNoIndex}
      />
      <div className={'flex items-center flex-col h-1/2'}>
        <h1 className={'text-[38px] text-center font-medium'}>
          This page doesn’t exist.
        </h1>
        <RetainQueryLink href={'/'}>
          <button
            className={
              'w-auto text-black bg-[#14F195] rounded-full py-[15px] font-bold mt-5 px-5 mt-[52px] px-[30px]'
            }
          >
            Go to the homepage
          </button>
        </RetainQueryLink>
      </div>
      <div className={'flex h-1/2'}></div>
    </div>
  )
}

export default NotFoundPage
